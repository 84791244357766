<template>
  <div>
    <h1 class="text-center text-lg font-bold text-darkblue font">
      Fortunate Steel and Industrial Materials Company Limited
    </h1>
    <div class="text-right">
      <p class="md:text-sm">
        <span class="font-bold text-darkblue "> HEAD OFFICE</span> : Km 17
        Aba/Port Harcourt Express Way, <br />
        By Interlock Junction, Iriebe, River State Nigeria.
      </p>
      <p class="md:text-sm">
        <span class="font-bold text-darkblue"> BRANCH OFFICE</span> : Shop 327
        Road 6,
        <br />
        Steel Village Km 17Aba/Port Harcourt Express Way,
        <br />
        Port Harcourt, River State.
      </p>
      <p class="md:text-sm">
        <span class="font-bold text-darkblue"> TEL</span> : +234-08037072015,
        08081332570
      </p>
      <p class="md:text-sm">
        <span class="font-bold text-darkblue"> EMAILS</span> :
        fortunatesteelltd@gmail.com
      </p>
    </div>
    <h2 class="text-center text-darkblue font-bold text-base mt-4 mb-4">
      {{ data.letter_head_title }}
    </h2>
    <div id="letterHeadFlex" class="flex justify-between flex-col md:flex-row">
      <div class="text-left">
        <p
          class="md:text-sm"
          v-for="(item, index) in data.left_side"
          :key="index"
        >
          <span class="font-bold text-darkblue"> {{ item.label }}</span> :
          {{ item.value }}
        </p>
      </div>

      <div class="text-right">
        <p
          class="md:text-sm"
          v-for="(item, index) in data.right_side"
          :key="index"
        >
          <span class="font-bold text-darkblue"> {{ item.label }}</span> :
          {{ item.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LetterHead",
    props: {
      data: Object,
    },
    components: {},
    data() {
      return {};
    },
    computed: {
      new_mode: function() {
        return this.mode === "new";
      },
      old_mode: function() {
        return this.mode === "old";
      },
    },
    methods: {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
