export const printMixin = {
  methods: {
    Print() {
      // this.$htmlToPaper(id);
      // Get HTML to print from element
      const prtHtml = document.getElementById("fortunatePrint").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "_blank",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body onafterprint="self.close()">
            ${prtHtml}
          </body>
        </html>`);
      WinPrint.document.close();
    },
  },
};
